<template>
  <div>
    <b-container fluid>
      <b-row no-gutter class="banner"></b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=EB+Garamond");

.banner{
  background-image: url('./assets/banner.svg');
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

</style>
